@import './assets/css/variables.scss'; 

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    background-color: $negro;
    color: $blanco;
}

.box {
    border: 3px solid $rosa;
    padding: 1.5rem;
    text-align: center;
}



