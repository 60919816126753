@import '../../assets/css/variables.scss';

header {
    padding-top: 3rem;
    padding-bottom: 2rem;
    h1 {
        text-align: center;
        font-size: 3.5rem;
        font-weight: 900;
        text-transform: uppercase;

        @include respond(sm) {
            background-color: $negro;
            padding: 0 3rem 1rem 0;
            font-size: 3rem;
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 250px;
        font-weight: 500;
        font-size: 1.6rem;

        @include respond(sm) {
            padding-right: 4rem;
            width: 300px;
            position: relative;
        }

        li:hover {
            cursor: pointer;
        }
    }

    @include respond(sm) {
        position: absolute;
        justify-content: space-between;
        width: 85vw;
        margin-left: calc(7.5vw - 2px);
        margin-top: 2rem;

        ul::before {
            content: '';
            -webkit-mask: url('../../assets/icons/xmark.svg') no-repeat 50% 50%;
            mask: url('../../assets/icons/xmark.svg') no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-color: $blanco;
            width: 12px;
            height: 12px;
            position: absolute;
            right: 7%;
            top: -30%;
        }
    }
}

main {
    @include respond(sm) {
        padding-top: 5rem;
    }
}

#about {
    background-color: $gris;
    max-width: 85%;
    margin: 0 auto;

    
    .presentation {
       
        padding-top: 4rem;
        border-top: 4px solid $rosa;
        
        img {

            max-width: 210px;
            border-radius: 50%;
            z-index: 2;
            cursor: pointer;
        }

        .draggg {
            z-index: 1;
        }

        h2 {
            margin: 2.5rem 0 0.5rem 0;
            font-size: 5rem;
            text-align: center;
            text-transform: uppercase;
            font-weight: 900;
            font-style: italic;
            text-shadow: 4px 0px 0px $rosa;
            cursor: default;
            z-index: 3;
        }

        h3 {
            background-color: $verde;
            padding: 1rem 2rem;
            font-size: 2rem;
            font-weight: 700;
            color: $negro;
            margin-top: 3rem;
            cursor: pointer;
            box-shadow: 4px 2px 0px $rosa;
        }

        p {
            text-align: center;
            font-size: 1.6rem;
            z-index: 3;

            margin: 1.5rem 0 0.2rem 0;
        }

        .pres {
            font-size: 1.9rem;
        }
        @include respond(sm) {
            padding-top: 12rem;

            img {
                max-width: 350px;
            }

            h2 {
                font-size: 10rem;
                
            }

            h3 {
                padding: 1.5rem 3rem;
                font-size: 2.5rem;
                margin-top: 3rem;
            }
            
            p {
                font-size: 1.8rem;

                
                
            }
            .pres {
                font-size: 2.2rem;

                br {
                    display: none;
                }
            }
        }

    }

    .skills {

        margin-top: 9rem;

        .box {
            font-weight: 400;
            text-align: center;
            max-width: 85%;
            margin: 0 auto;
            margin: 0 auto;
            margin-top: 5rem;
            margin-bottom: 3rem;

            
            h3 {
                font-size: 1.5rem;
                margin: 0;
                padding: 0;
            }
        }

        .skills-list {
            .col1 {
                width: 30%;
                h2 {
                    writing-mode: vertical-rl;
                    transform: rotate(180deg);
                    color: $azul;
                    font-size: 12rem;
                    padding: 0;
                    font-weight: 900;
                    margin: 0;
                    line-height: 0.6;
                }
            }
            .col2 {
                width: 70%;
                position: relative;
                background-color: $azul;
                ul {
                    list-style: none;
                    font-size: 1.6rem;
                    max-width: 150px;
                    

                    li {
                        
                        cursor: pointer;
                    }
                }
            }

            .col2::before {
                content: "";
                position: absolute;
                background-color: $azul;
                width: 30%;
                height: 100%;
                right: calc(-12% - 2px);
            }
        }

        @include respond(sm) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            margin-top: 10rem;
            .box-container {
                width: 60%;
                display: flex;
                margin: 0;
                position: relative;
                .box {
                    flex-direction: column;
                    height: 100px;
                    position: absolute;
                    margin-left: 5rem;
                    padding: 0 5rem;
                    bottom: 0;

                    h3 {
                        font-size: 2rem;
                    }
                }
            }
            
            .skills-list {
                height: 410px;
                width: 40%;
                justify-content: end;
                .col1 {
                    width: 50%;
                    h2 {
                        font-size: 15rem;
                        text-align: center;
                    }
                }
                .col2 {
                    max-width: 250px;
                    width: 50%;

                    
                    li {
                        font-size: 2rem;
                    }
                }
                .col2::before {
                    display: none;
                }
            }
        }
    }
    
}

#work {
    background-color: $azul;
    padding-bottom: 7rem;

    .title {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
        h2 {
            width: 100%;
            text-align: right;
            font-weight: 900;
            color: $gris;
            font-size: 12rem;
        }
    }
    
    .work-content {
        justify-content: flex-start;
        column-gap: 25px;
    }

    > div {
        max-width: 85%;
        margin: 0 auto;

        padding: 0 2rem;

        .tech-content {
            padding: 1.5rem 0;
            margin: 0;
            justify-content: center;
            .tech {
                justify-content: center;
                margin-bottom: 1.5rem;
                img {
                    max-width: 75px;
                }
            }
            
        }
        .img-content {
            position: relative;
            margin: 0;
            padding: 0;
            width: 100%;

            >div {
                position: absolute;
                padding-left: 1rem;
                top: 2%;
            }

            img {
                width: 100%;
                border-top: 35px solid $gris;
                margin: 0;
                padding: 0;
                
            }
            .tech-content-desk {
                position: absolute;
                height: calc(100% - 34px);
                width: 100%;
                background: rgba(96,102,133,0.5);
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                padding: 0;
                opacity: 0;
                top: calc(0% + 35px);
                display: none!important;
                transition: opacity 0.4s 0s ease;
                
                div {
                    padding: 0;
                    display: flex;
                    justify-content: center;
                }
                img {
                    border: none;
                    width: 85%;
                    padding: 5px


                }

                img:hover {
                    cursor: pointer;
                }
            }
        }
        .img-content::before {
            content: '';
            -webkit-mask: url('../../assets/icons/xmark.svg') no-repeat 50% 50%;
            mask: url('../../assets/icons/xmark.svg') no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-color: $blanco;
            width: 12px;
            height: 12px;
            position: absolute;
            right:2%;
            top: 2%;
        }

        
    }

    @include respond(sm) {

        .title {
            width: 85%;
            margin: 0 auto;
            h2 {
                width: 100%;
                margin: 0 auto;
                font-size: 15rem;
                margin: 0;
                margin-top: -30px;
            }
        }
        > div {
            justify-content: space-between;
            padding: 0 5%;
            display: flex;
            > div {
                
                width: 50%;
                position: relative;
                margin-bottom: 3rem;
                .img-content{
                    width: 100%;

                    

                    .tech-content-desk {
                        display: flex!important;
                        div {
                            width: 30%; 

                        }
                    }
                    
                }
                .img-content:hover {
                    .tech-content-desk {
                        opacity: 1;
                        transition: opacity 0.4s 0s ease;
                    }
                }

                .tech-content {
                    display: none;
                }

                @include respond(md) {
                    width: 33%;
                }
            }
            
            ul {
                display: none;
            }
        }
    }
}

#contact {
    max-width: 85%;
    margin: 0 auto;
    background-color: $gris;
    padding: 3rem 0 6rem 0;

    h2:first-of-type {
        font-size: 7rem;
        line-height: 1;
        font-weight: 900;
        text-align: right;
        color: $azul;
        padding-right: 2rem;
        margin-top: -3.7rem;
    }
    h2:last-of-type {
        max-width: 85%;
        margin: 0 auto;
        font-size: 5rem;
        line-height: 1;
        font-weight: 900;
        color: $verde;
    }

    .box {
        font-weight: 400;
        text-align: center;
        max-width: 85%;
        margin: 0 auto;
        margin-top: 5rem;
        margin-bottom: 10rem;
        display: flex;
        justify-content: center;
        
        h3 {
            font-size: 1.5rem;
            margin: 0;
            padding: 0;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        max-width: 85%;
        margin: 0 auto;
        margin-top: 2rem;

        li {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 1rem;
            a {
                padding: 0;
                color: $blanco;
                margin: 0;
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            span {
                color: $verde;
                font-weight: 700;
                font-size: 2rem;
                margin: 0 0.3rem!important;
            }
            p, span {
                padding: 0;
                margin: 0;
            }
            img {
                width: 35px;
            }

            
          
        }
    }

    @include respond(sm) {
        position: relative;
        background-color: $negro;
        h2:first-of-type {
            font-size: 13rem;
            text-align: left;
            width: 85%;
            margin: 0 auto;
            margin-top: -4.5rem;
        }
        h2:last-of-type {
            font-size: 10rem;
            margin-top: 15rem;
        }
        .box {
            width: 350px;
            position: relative;
            padding: 2rem 0;
            //top: -6rem;
            //transform: rotate(337deg);

            h3 {
                font-size: 2rem;
            }
            
        }

        ul {
            margin-top: 5rem;
    
            li {
                cursor: pointer;
                margin-bottom: 1rem;

                span {
                    color: $verde;
                    font-weight: 700;
                    font-size: 2.2rem;
                    margin: 0 0.5rem!important;
                }
                img {
                    width: 35px;
                }
            }
        }
    }
}

footer {
    background-color: $verde;
    padding: 1.5rem 0;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: center;

        li {
            cursor: pointer;
            padding: 0 1rem;

            img {
                width: 60px;
            }
        }
    }

    div {
        padding: 2rem 0 0 0;
        display: flex;
        justify-content: center;

        a {
            border: none;
            background-color: $negro;
            color: $verde;
            padding: 1rem 2rem;
            font-weight: 700;
            text-decoration: none;
        }

        a:hover {
            color: $verde;
        }
    }
}