$blanco: #d2c4c6;
$negro: #21222c;
$rosa: #d17194;
$gris: #343746;
$azul: #606685;
$verde: #50a471;

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

@mixin respond($breakpoint) {

    @if map-has-key($breakpoints, $breakpoint) {

        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (min-width: $breakpoint-value) {
            @content;
        }

    } @else {

        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}