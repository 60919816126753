@import '../../assets/css/variables.scss';

.goTop {
    background-color: #606685;
    position: fixed;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 3%;
    right: 1%;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid $negro;
    z-index: 9999;
    svg {
        width: 40%;
        fill: $negro;
    }

    @include respond(sm) {
        width: 60px;
        height: 60px;
    }
}